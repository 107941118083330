<template>
  <div>
    <!-- Title -->
    <h4>Global Products List</h4>

    <!-- Global Products Search -->
    <merchant-global-product-search :token="token" @input="onSearchChanged" />

    <!-- Product -->
    <div class="mt-3">
      <!-- No products Present -->
      <b-alert variant="info" v-if="!hasProducts" class="text-center" show>
        No Products Present
      </b-alert>

      <!-- Display Products -->
      <b-row>
        <!-- Add New Product -->
        <b-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="d-flex justify-content-center align-items-start text-center"
        >
          <b-button @click="addNewProduct" size="sm" variant="outline-primary">
            <div>
              <b-icon-plus-circle scale="1.5" class="my-1" />
            </div>
            <div>Create Product</div>
          </b-button>
        </b-col>

        <b-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="product in products"
          :key="product.id"
        >
          <MerchantGlobalProduct :product="product" class="mb-3" />
        </b-col>
      </b-row>

      <!-- Pagination -->
      <div v-if="hasProducts" class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="count"
          :per-page="perPageCount"
          @change="getPaginatedProducts"
          align="center"
        />
      </div>
    </div>

    <!-- Cancel -->
    <div class=" text-center mt-4">
      <b-button @click="onBack" size="sm" class="px-4">Back</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getGlobalProducts,
  getGlobalProductsCount,
  getGlobalProductsForGlobalCategory,
  getGlobalProductsCountForGlobalCategory,
} from "../../../apis/merchant";

import MerchantGlobalProductSearch from "../../../components/products/merchant/MerchantGlobalProductSearch.vue";
import MerchantGlobalProduct from "../../../components/products/merchant/MerchantGlobalProduct.vue";

export default {
  components: { MerchantGlobalProductSearch, MerchantGlobalProduct },
  created() {
    this.initGlobalProducts();
  },
  data() {
    return {
      // search filters
      selectedCategoryId: null,
      name: null,

      // products
      products: [],

      // for pagination
      currentPage: 1,
      perPageCount: 20,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    hasProducts() {
      return this.products.length > 0;
    },
  },
  methods: {
    addNewProduct() {
      this.$router.push({ name: "ProductForm" });
    },
    onBack() {
      this.$router.go(-1);
    },
    onSearchChanged(data) {
      // set categoryId
      this.selectedCategoryId = data.categoryId;
      this.name = data.name;

      // reset page
      this.currentPage = 1;
      this.initGlobalProducts();
    },
    selectCategory(category) {
      // set category
      this.selectedCategoryId = category.id;
      // reset page
      this.currentPage = 1;
      this.modalShow = false;
      this.initGlobalProducts();
    },
    initGlobalProducts() {
      const countParams = { name: this.name };
      if (this.selectedCategoryId) {
        getGlobalProductsCountForGlobalCategory(
          this.selectedCategoryId,
          this.token,
          countParams
        ).then((data) => this.onProductsCountSuccess(data));
      } else {
        getGlobalProductsCount(this.token, countParams).then((data) =>
          this.onProductsCountSuccess(data)
        );
      }
    },
    onProductsCountSuccess(data) {
      this.count = data.count;
      if (this.count) {
        this.getPaginatedProducts(this.currentPage);
      } else {
        this.onProductsSuccess([]);
      }
    },
    getPaginatedProducts(page) {
      // first set the page
      this.currentPage = page;

      // make api call
      const pageable = {
        page: this.currentPage - 1,
        size: this.perPageCount,
        // sort: sortBy ? (sortDesc ? `${sortBy},desc` : sortBy) : "",
      };
      const params = { ...pageable, name: this.name };
      if (this.selectedCategoryId) {
        getGlobalProductsForGlobalCategory(
          this.selectedCategoryId,
          this.token,
          params
        ).then(this.onProductsSuccess);
      } else {
        getGlobalProducts(this.token, params).then(this.onProductsSuccess);
      }
    },
    onProductsSuccess(data) {
      this.products = [...data];
    },
  },
};
</script>
