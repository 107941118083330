<template>
  <b-card class="product-card border-0" no-body>
    <!-- Image Card -->
    <b-card class="image-card">
      <a @click="onClick" :class="[{ 'cursor-pointer': addImageClick }]">
        <b-img :src="imageSrc" style="height: 100%" fluid center />
      </a>
    </b-card>

    <!-- Product Info Card -->
    <b-card class="product-info-card" body-class="text-center p-1 mt-2 mb-3">
      <slot />
    </b-card>
  </b-card>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
    },
    addImageClick: {
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      if (this.addImageClick) this.$emit("click");
    },
  },
};
</script>

<style scoped>
.image-card {
  height: 150px;
}
</style>
