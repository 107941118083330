<template>
  <!-- Search Input with Button -->
  <b-row no-gutters>
    <b-col col sm="4">
      <!-- Categories -->
      <b-form-select
        v-model="form.categoryId"
        :options="categories"
        value-field="id"
        text-field="name"
        @change="onCategoryChange"
      >
        <!-- This slot appears above the options from 'options' prop -->
        <template #first>
          <b-form-select-option :value="null">
            -- All --
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-col>

    <b-col col sm="8">
      <!-- Input -->
      <b-form-input
        type="search"
        id="name"
        v-model="form.name"
        placeholder="Search Products"
        @input="onInputChange"
        trim
      />
    </b-col>
  </b-row>
</template>

<script>
import { getGlobalCategories } from "../../../apis/merchant";
export default {
  props: ["token"],
  created() {
    getGlobalCategories(this.token).then((data) => {
      this.categories = data;
    });
  },
  data() {
    return {
      form: {
        categoryId: null,
        name: null,
      },
      categories: [],
    };
  },
  methods: {
    onCategoryChange() {
      this.form.name = "";
      this.emit();
    },
    onInputChange() {
      this.emit();
    },
    emit() {
      this.$emit("input", this.form);
    },
  },
};
</script>

<style></style>
