<template>
  <product-card
    :imageSrc="imageSrc"
    :addImageClick="images.length > 0"
    @click="() => (showCarouselModal = true)"
  >
    <template>
      <!-- Title -->
      <div class="store-product-title">
        {{ title }}
      </div>

      <div class="store-product-title">
        {{ `Unit: ${unit}` }}
      </div>

      <b-button
        @click="selectProductToUse"
        variant="primary"
        size="sm"
        class="mt-3"
        pill
      >
        Use Product
      </b-button>
    </template>

    <b-modal title="Images" v-model="showCarouselModal" size="sm" hide-footer>
      <b-carousel :interval="2500" indicators no-hover-pause>
        <!-- Slider -->
        <b-carousel-slide v-for="({ src }, index) in this.images" :key="index">
          <!-- Image -->
          <template v-slot:img>
            <b-img class="product-image" :src="src" thumbnail />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>

    <!-- Confirmation Modal -->
    <b-modal
      v-model="showConfirmModal"
      @ok="onConfirm"
      title="Confirm create product"
      ok-title="Create"
      size="sm"
      body-class="text-center"
      centered
    >
      <div class="mb-2">
        Creating a new product using this products as a template!
      </div>
      <div class="small">
        Please fill in the other product data once it has been created
      </div>
    </b-modal>
  </product-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getGlobalProductImages } from "../../../apis/merchant";

import ProductCard from "../../common/ProductCard.vue";

export default {
  components: { ProductCard },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    getGlobalProductImages(this.product.id, this.token).then((data) => {
      // array of productImages setting to state
      const images = data.map((image) => {
        const src = image.productImage.blobUrl;
        return { id: image.id, src };
      });
      this.images = images;
    });
  },
  data() {
    return {
      title: this.product.name,
      unit: this.product.unit,

      images: [],

      showCarouselModal: false,
      showConfirmModal: false,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    imageSrc() {
      const productImages = this.images;
      if (productImages.length > 0) {
        const src = productImages[0].src;
        return src;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      addProductToState: "merchant/products/addItem",
    }),
    selectProductToUse() {
      // this.showConfirmModal = true;
      this.onConfirm();
    },
    onConfirm() {
      this.$router.push({
        name: "ProductForm",
        params: { globalProduct: this.product },
      });
    },
  },
};
</script>
